<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-server menu="digital"></submenu-server>

      <div class="submenu-content">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <!-- show page content -->
        <div v-if="item">
          <!-- page title -->
          <h2>{{item.intro}}</h2>
          <br/>

          <div v-for="(b, index) in item.body">

            <div v-if="b.type === 'heading'">
              <div v-if="b.value === 'Inquiry Button'">
                <br/>
                <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
                <br/><br/>
              </div>
              <div v-else>
                <div v-if="index > 0" class="dash"></div>
                <h4>{{b.value}}</h4>
              </div>
            </div>

            <div v-else-if="b.type === 'paragraph'" class="paragraph">
              <p v-html="b.value"></p>
            </div>

            <div v-else-if="b.type === 'image'">
              <img
              :src="b.value.large.src"
              :width="b.value.large.width"
              :height="b.value.large.height">
            </div>
            
          </div>
        
        <!-- end of page content -->
      </div>

  </div>
  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'DigitalSolutions',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Digital Solutions", link: "DigitalSolutions"})

    getAPI
    .get('/content/pages/56')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
.clear {clear:both;}
.pad {margin-right:50px;}
.paragraph {margin:30px 0;}
.block {width:30%; margin: 50px 30px 10px 0; height: 300px;}
.underline {text-decoration: overline;}

.dash {border-top: dotted 3px #ccc; margin-bottom:20px;}

</style>